@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,700;0,900;1,100&display=swap);
.App {
  text-align: center;
  height: 100%;
  font-family: "Roboto", sans-serif;
}

body {
  background-color: #c5f2e6;
  color: #004c6d;
}

.headerDiv {
  display: inline;
}

.App-logo {
  height: 12vh;
  font-size: 60px !important;
  float: left;
}

.Header {
  width: 100%;
  position: fixed;
  padding: 0.3rem;
  top: 0;
  left: 0;
  right: 0;
  background-color: #d25876;
  z-index: 9999 !important;
}

.Menu {
  text-align: justify;
  display: flex;
  float: right;
  letter-spacing: 3px;
  text-decoration: none;
  height: 12vh;
  justify-content: center;
  justify-items: center;
  margin: auto;
  padding-right: 30px;
}

.Menu2 {
  text-align: center;
  align-items: center;
  justify-content: center;
  letter-spacing: 3px;
  margin: 50% auto;
}

.Menu a {
  /* font-family: Arial; */
  text-decoration: none;
  color: #f2f3f5;
  margin: auto;
}

.Menu2 a {
  align-items: center;
  justify-content: center;
  /* font-family: Arial; */
  text-decoration: none;
  color: #f2f3f5;
  font-size: 6vw;
  /* align-items: center;
text-align: center; */
  z-index: 4000 !important;
  visibility: visible;
  /* margin: auto; */
  /* margin: 40px; */
  /* text-shadow: .3px .5px 1px #004C6D; */
}

.nav-small {
  float: right;
  right: 0;
  padding: 0;
}

@media screen and (max-width: 44em) {
  .nav-small {
    display: block;
  }

  .Menu {
    display: none;
  }

  input {
    width: 10vh;
    /* color: #004c6d; */
  }

  .contactSelect {
    width: 10vh;
    min-height: 35px;
    padding: 10px;
  }

  .Home {
    /* min-height: 20vh; */
  }

  .PopUps {
    /* margin: 20px !important; */
  }

  .form-container {
    width: auto;
    padding: 20px;
  }

  .title {
    /* font-family: "Arial Black" !important; */
  }

  textarea {
    height: 25vw !important;
  }

  .carousel-container {
    height: auto;
  }

  .carousel-item {
    width: auto;
  }

  .carouselImage {
    width: auto;
    object-fit: contain;
  }

  .sidebar.active {
    background-color: #0096ca;
    opacity: 0.9;
    /* margin-top: 8px; */
    z-index: 9999;
    /* opacity: 80%; */
  }

  .mucaroValues {
    display:flex !important;
   flex-direction: column;
  }
}

@media screen and (min-width: 45em) {
  .nav-small {
    display: none;
  }
}

h5 {
  padding: 10px;
}

.Home {
  /* min-height: 75vh; */
  height: auto;
  /* padding: 40px auto; */
  margin: 3rem 1rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #004c6d;
  text-align: left;
  /* margin: 60px; */
}

.homeContainer {
  min-width: auto;
  /* min-height: auto; */
  overflow: scroll;
  background-color: #f2f3f5;
  border-radius: 10px;
  text-align: left;
  margin: 80px 0 60px;
  padding: 20px;
  z-index: -2;
}

p.homeInfo {
  padding: 5px;
  /* text-indent: 30px; */
  font-size: 16px;
  /* font-family: "Arial"; */
}

.PopUps {
  /* min-height: 75vh; */
  /* padding: 40px 0 60px; */
  margin: 3rem 1rem;
  display: flex;
  flex-direction: column;
  color: #004c6d;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.popUpsContainer {
  min-width: auto;
  background-color: #f2f3f5;
  border-radius: 10px;
  text-align: left;
  margin-top: 80px;
  padding: 20px;
}

.popUpsInfo {
  font-size: 16px;
  /* font-family: "Arial"; */
  color: "#004c6d";
}

.About {
  /* min-height: 75vh; */
  /* padding: 40px 0 60px; */
  margin: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  color: #004c6d;
  overflow: scroll;
}

.aboutContainer {
  min-width: auto;
  /* height: 100%; */
  overflow: scroll;
  background-color: #f2f3f5;
  border-radius: 10px;
  text-align: left;
  /* margin: auto; */
  margin: 80px 0 60px;
  padding: 20px;
  text-align: left;
}

.mucaroValues {
  width: 100%;
  /* background-color: pink; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  /* space between columns for demo */
  /* grid-gap: 5px; */
}

.Faqs {
  /* min-height: 75vh; */
  margin: 3rem 1rem;
  /* padding: 40px 0 60px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  color: #004c6d;
}

.faqsContainer {
  min-width: 95%;
  height: "100%";
  overflow: scroll;
  background-color: #f2f3f5;
  border-radius: 10px;
  text-align: left;
  margin: 80px 0 60px;
  padding: 20px;
  text-align: left;
  /* font-size: 1rem; */
}

.Contact {
  /* min-height: 75vh; */
  /* padding: 40px 0 60px; */
  margin: 3rem 1rem;
  display: flex;
  flex-direction: column;
  color: #004c6d;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.faqLink {
  /* text-decoration: "none"; */
  color: #0096ca;
  font-weight: bold;
}

.faqTitle {
  display: flex;
  /* color: #0096ca; */
}

.faqContent {
  margin-left: 30px;
  /* color: #004C6D; */
}

.form-container {
  min-width: auto;
  overflow: scroll;
  background-color: #f2f3f5;
  border-radius: 10px;
  text-align: left;
  margin-top: 80px;
  padding: 20px;
  text-align: left;
}

.form label {
  /* font-size: 16px; */
  font-weight: bold;
}

/* .form-container p {
  font-size: 13px;
} */

/* .form-container li{
  font-size: 13px;
} */

.contactSelect {
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: auto;
  /* background-color: #e8e8e8; */
  /* color: #004c6d; */
  font-size: 13px;
}

select {
  height: 35px;
  /* opacity: 75%; */
  color: #777b7e;
  background-color: #e8e8e8;
}

label {
  /* font-size: 12px; */
}

.aboutContainer p,
.faqsContainer p {
  /* text-indent: 30px; */
  margin-left: 30px;
}

h4 {
  color: #0096ca;
}

p {
  font-size: 16px;
  color: "#004c6d";
}

.title {
  margin-top: 20px;
  color: #d25876;
  font-weight: 900;
  font-family: 'Roboto', sans-serif;
  font-size: 25px;
  letter-spacing: 0.5rem;
  text-align: center;
}

button {
  background-color: #c5f2e6;
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 80px;
  margin: 20px auto 20px;
}

.message {
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  min-height: 10vh;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.form {
  display: flex;
  flex-flow: column wrap;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  margin: 0 auto;
  color: #0096ca;
}

.form-container {
  min-width: 175px;
  background-color: #f2f3f5;
  border-radius: 10px;
  text-align: left;
  margin: 80px 0 80px 0;
  padding: 20px;
}

input {
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: auto;
  background-color: #e8e8e8;
  color: #004c6d;
  /* opacity: 75%; */
}

input::-webkit-input-placeholder {
  color: #777b7e;
  font-size: 13px;
  /* opacity: 95%; */
  /* font-style: italic; */
}

input::placeholder {
  color: #777b7e;
  font-size: 13px;
  /* opacity: 95%; */
  /* font-style: italic; */
}
textarea::-webkit-input-placeholder {
  color: #777b7e;
  /* opacity: 65%; */
  font-size: 13px;
  /* font-style: italic; */
}
textarea::placeholder {
  color: #777b7e;
  /* opacity: 65%; */
  font-size: 13px;
  /* font-style: italic; */
}

option::-webkit-input-placeholder {
  /* opacity: 75%; */
  font-style: italic;
}

option::placeholder {
  /* opacity: 75%; */
  font-style: italic;
}

textarea {
  resize: vertical;
  width: auto;
  min-height: 2vh;
  background-color: #e8e8e8;
  /* background-color: #E8E8E8; */
  /* color: #004c6d; */
  outline: none;
  /* letter-spacing: .1px; */
}

.send {
  color: #0096ca;
}

.Footer {
  text-align: center;
  /* font-family: arial; */
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  margin: 0 auto;
  letter-spacing: 5px;
  /* font-size: px; */
  background-color: #c5f2e6;
  color: #004c6d;
  padding: 10px;
}

.insta-logo {
  height: 4vmin;
}

.instalink {
  color: #0096ca;
}

.Footer p {
  font-size: 12px;
}

/* ---Sidebar css */

.sidebar {
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;

  /* min-height: 100vh; */
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  /* background-image: linear-gradient(to bottom, #0096ca, #3eafcf, #6cc6d3, #99ddda, #c5f2e6); */
  background-color: #0096ca;
  position: fixed;
  top: 0;
  /* z-index: 9999; */
  left: -100%;
  transition: 0.3s;
}

.sidebar.active {
  left: 0;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* z-index: -3;  */
  visibility: visible;
}

.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: 15px;
}
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}
.sidebar-overlay.active {
  opacity: 0.1;
  visibility: visible;
}
.sd-body {
  padding: 15px;
  /* max-height: calc(100vh - 67px); */
  overflow-x: hidden;
}
/* .sd-body ul{
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
} */
/* .sd-body ul li{
    list-style: none;
    margin-bottom: 8px;
} */
/* .sd-body ul li .sd-link{
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    background-color: #e5e8ec;
    background-color: red;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
} */

/* ---Carousel css */

.carousel-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 40vmax;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  /* background-color: #D25876; */
}

.carousel-item {
  /* width: 40vw; */
  width: 40vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* background-color: #D25876; */
  -webkit-animation: fadeIn 3s infinite;
          animation: fadeIn 3s infinite;
  transition: 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) ease-in-out;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.carouselImage {
  border-radius: 10px;
  width: 40vmax;
  height: auto;
  object-fit: contain;
}

/* ---Accordion css */

.accordionIcon {
  color: #d25876;
}

/* .accordionItem {
  overflow: scroll;
} */

